import "../css/app.css";
import 'lazysizes';
import Alpine from 'alpinejs';
import focus from '@alpinejs/focus';
import collapse from '@alpinejs/collapse';
import "./scroll-to-top";
import * as Sentry from "@sentry/browser";

// Vite
if(import.meta.hot) {
    import.meta.hot.accept(() => {
        console.log("HMR");
    });
}

// Setup Alpine.js
window.Alpine = Alpine
Alpine.plugin(focus);
Alpine.plugin(collapse);
Alpine.start();

Sentry.init({
    dsn: "https://cc7639de1f9dd3a7517609fed9cda722@o1102039.ingest.us.sentry.io/4507314352947200",

    enabled: process.env.NODE_ENV === 'production',
    // This sets the sample rate to be 10%. You may want this to be 100% while
    // in development and sample at a lower rate in production
    replaysSessionSampleRate: 0.1,

    // If the entire session is not sampled, use the below sample rate to sample
    // sessions when an error occurs.
    replaysOnErrorSampleRate: 1.0,

    integrations: [
        Sentry.replayIntegration({
            // Additional SDK configuration goes in here, for example:
            maskAllText: false,
            blockAllMedia: false,
        }),
    ],
});